<template>
  <div>
    <div class="flex flex-row items-center justify-between">
      <h1 class="text-2xl font-semibold text-gray-900">Facturatie - Controle lijst</h1>
      <UISelectFixed v-model="form.journal_id" type="FINANCIAL_JOURNALS" />
    </div>

    <UISelectStatsFilter verberg-type verberg-day min-year="2023" v-model:month="form.month" v-model:year="form.year" />

    <div v-if="hasData" class="mt-8">
      <div v-if="response?.klaar" v-html="response.klaar" class="card"></div>
      <div v-if="response?.missend" v-html="response.missend" class="card"></div>
      <p v-if="!response?.klaar && !response?.missend" class="no-data">
        Alles in orde!
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import useApi from '@/hooks/useApi'

import UISelectStatsFilter from '@/components/UI/Select/StatsFilter.vue'
import UISelectFixed from '@/components/UI/Select/Fixed.vue'

const api = useApi()

const form = ref({
  journal_id: 1,
  month: new Date().getMonth() + 1,
  year: new Date().getFullYear(),
})

const response = ref({})

const hasData = computed(() => Object.keys(response.value).length > 0)

const getData = () => {
  const params = {
    ...form.value,
    controle: true,
    isControleTabel: true,
  }

  api('FINANCIAL_DOCS_GENERATE_BY_DATE', params).then(({ data }) => {
    response.value = data || {}
  }).catch(() => {
    response.value = {}
  })
}

onMounted(getData)
watch(form, getData, { deep: true })
</script>
