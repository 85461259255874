<template>
  <span class="font-bold">
    <span class="text-red-600" v-if="!shift?.start">Shift nog niet gestart</span>
    <span class="text-orange-400" v-else-if="!shift?.aangekomen">Onderweg naar klant</span>
    <span class="text-orange-400" v-else-if="!shift?.klant_ingestapt">Wachtend op de klant</span>
    <span class="text-orange-400" v-else-if="!shift?.klant_afgezet">Klant opgehaald, maar nog niet afgezet</span>
    <span class="text-orange-400" v-else-if="!shift?.einde">Klant afgezet, maar shift nog niet beeïndigd</span>
    <span class="text-green-500" v-else-if="shift?.einde">Shift ingevuld</span>
  </span>
</template>

<script>
export default {
  props: {
    shift: Object,
  },
}
</script>
