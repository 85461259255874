import type {SettingsVasteAfstandKostenType} from 'types/Settings'

export const getVasteAfstandKosten = (
  kilometers: number,
  SETTINGS_VASTE_AFSTAND_KOSTEN: SettingsVasteAfstandKostenType,
): number => {
  const thresholds = Object.keys(SETTINGS_VASTE_AFSTAND_KOSTEN)
    .map(Number)
    .sort((a, b) => b - a)

  for (const threshold of thresholds) {
    if (kilometers >= threshold) {
      return SETTINGS_VASTE_AFSTAND_KOSTEN[String(threshold)]
    }
  }

  return 0
}
