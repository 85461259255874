<template>
  <div>
    <h1>Nieuwe offerte</h1>
    <router-link replace :to="{ path: route.path.replace('offerte', 'ritten'), query: { ...route.query, storageKey } }">
      <i class="fas fa-sync text-xs"></i> Naar reservatie omvormen
    </router-link>
    <FormRit v-model="formInStorage" is-offerte @submit="onSubmit" />
  </div>
</template>

<script setup>
import { useStorage } from '@vueuse/core'
import { useRoute, useRouter } from 'vue-router'
import useApi from '@/hooks/useApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'
// import { dateString } from '@/functions/formatDate'
import FormRit from '@/components/Form/Rit.vue'

const api = useApi()
const route = useRoute()
const router = useRouter()

const storageKey = route.query.storageKey || `OfferteData-${Date.now()}`
const formInStorage = useStorage(storageKey, {})
if (!route.query.storageKey) router.replace({ query: { ...route.query, storageKey } })

const onSubmit = data => {
  api('OFFERTE_CREATE', data).then(() => {
    if (formInStorage) formInStorage.value = null
    // if (route.query.from) {
    //   useRedirectSafe(decodeURIComponent(route.query.from))
    //   return
    // }
    // const date = dateString(data.heenreis.datum) || undefined
    // useRedirectSafe({ name: 'Kalender', query: { date } })
    useRedirectSafe({ name: 'Boekingen', query: { type: 'OFFERTES' } })
  })
}
</script>
