export const isLastMinute = (dateParam: number | Date | string, todayParam = Date.now()) => {
  if (!dateParam) return false

  const date = new Date(dateParam)
  const isValidDate = (date instanceof Date && isFinite(date.getTime()))

  if (!isValidDate) return false

  const today = new Date(todayParam)
  const tomorrow = new Date(today.getTime() + 86400000)

  if (date.getTime() <= today.getTime()) {
    return true
  }

  if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth() && today.getFullYear() === date.getFullYear()) {
    return true
  }

  if (tomorrow.getDate() === date.getDate() && tomorrow.getMonth() === date.getMonth() && tomorrow.getFullYear() === date.getFullYear()) {
    return true
  }

  return false
}

