import useFindLuchthaven from './useFindLuchthaven'

/**
 * Formateer adressen naar een zoekterm
 */

export default (adressenDTO) => {
  const adressen = (!adressenDTO || typeof adressenDTO === 'string' ? [] : adressenDTO)
  return adressen
    .map(el => {
      const adres = (el.adres || el)
      const luchthaven = useFindLuchthaven(adres)
      if (luchthaven?.name) {
        return `Luchthaven ${useFindLuchthaven(adres)?.name} ${adres}`
      }
      return adres
    })
    .join(' ')
}
