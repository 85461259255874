import clone from 'just-clone'
import { computed } from 'vue'
import { getBerekening } from '@taxiboeken-v2/utilities-berekening'

export default (form, typeVervoerComputed) => {
  const berekening = computed(() => {
    const data = clone(form.value)

    const heenAdressen = (data?.heenreis?.adressen || []).filter(el => el?.adres)
    const retourAdressen = (data?.retour?.adressen || []).filter(el => el?.adres)

    if (!data?.heenreis?.datum || heenAdressen.length < 2 || !data?.heenreis?.kilometers) {
      return null
    }

    if (data?.is_retour && (!data?.retour?.datum || retourAdressen.length < 2 || !data?.retour?.kilometers)) {
      return null
    }

    const baseOptions = {
      adressen: [], // Concatenated hieronder
      adressen_heen: [].concat(heenAdressen).filter(el => !!el),
      adressen_retour: !data.is_retour ? [] : [].concat(retourAdressen).filter(el => !!el),
      kilometers_heen: data.heenreis.kilometers,
      kilometers_retour: !data.is_retour ? 0 : data.retour.kilometers,
      personen_heen: data.heenreis.personen,
      personen_retour: !data.is_retour ? 0 : data.retour.personen,
      bagage_heen: data.heenreis.bagage,
      bagage_retour: !data.is_retour ? 0 : data.retour.bagage,
      handbagage_heen: data.heenreis.handbagage,
      handbagage_retour: !data.is_retour ? 0 : data.retour.handbagage,
      kost_tol_heen: data.heenreis.tolkosten,
      kost_tol_retour: !data.is_retour ? 0 : data.retour.tolkosten,
      btw_percentage: data.btw_percentage,
      korting: data.korting,
      korting_promotie: data.korting_promotie,
      extra_kost: data.extra_kost,
      prijs_verhoging: data.prijs_verhoging,
      is_retour: !!data.is_retour,
      is_ophaal_luchthaven: heenAdressen[0]?.is_luchthaven,
      is_bestemming_luchthaven: heenAdressen[heenAdressen.length - 1]?.is_luchthaven,
      is_prive_vervoer: !!data.is_prive_vervoer,
      is_luxe_vervoer: typeVervoerComputed.value /* componentData.value.typeVervoer */ === 'luxe',
      is_budget_vervoer: typeVervoerComputed.value /* componentData.value.typeVervoer */ === 'budget',
      is_wakeup_call: !!data.is_wakeup_call,
      is_last_minute: data.is_last_minute,
      afwijking_minuten: data.afwijking_minuten,
      korting_afwijking: data.korting_afwijking,

      isPrijsOnly: false,
      isSiteBoeking: false,
    }

    baseOptions.adressen = [].concat(baseOptions.adressen_heen, baseOptions.adressen_retour)

    const version = String(data.berekening || '') || undefined
    const berekeningFn = getBerekening(data.bedrijf_thema, version)
    const result = berekeningFn(clone(baseOptions))

    console.log({ version, baseOptions, berekeningResult: result })

    return result
  })

  return berekening
}
