import {FormBerekeningType} from 'types/Formulier'

const isPriveVerplichtAantal = (aantal: number) => aantal % 8 === 0 || aantal % 7 === 0

export const isPriveVerplicht = (form: FormBerekeningType): boolean => {
  if (form.is_prive_vervoer) return false

  const personen_heen = Math.max(form.heenreis.personen || 1, 1)

  if (form.is_retour) {
    const personen_retour = Math.max(form.retour.personen || 1, 1)
    return isPriveVerplichtAantal(personen_retour) && isPriveVerplichtAantal(personen_heen)
  }

  return isPriveVerplichtAantal(personen_heen)
}
