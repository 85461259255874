import type {SettingsBerekeningType} from 'types/Settings'

import {SETTINGS_BEREKENING as SETTINGS_BEREKENING_V1002} from 'berekeningen/TAXILUCHTHAVEN/v1002/SETTINGS_BEREKENING'

export const SETTINGS_BEREKENING: Readonly<SettingsBerekeningType> = Object.freeze({
  ...SETTINGS_BEREKENING_V1002,

  luxeExtraKostEnkeleReis: 45,
  luxeMoetPriveVervoerZijn: true,
})
