import axios from 'axios'
import useWindow from '@/hooks/useWindow'
import { set as setNotification } from './notification'
import store from '@/store'
import axiosCsrf from './axiosCsrf'
import { nextTick, ref } from 'vue'

const axiosLoading = ref(0)

export const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_AXIOS || '/',
  timeout: 15000,
  withCredentials: true,
  headers: { 'X-Platform': 'DASHBOARD' },
})

const getApiNotif = (html, type) => ({
  html,
  type,
})

const redirect = path => {
  if (useWindow()) {
    window.location.href = path
  }
}

const handleAxios = (status, data, res) => {
  if (status === 401) {
    if (window.location.pathname !== '/login') {
      redirect(`/login?from=${window.location.pathname}${window.location.search || ''}`)
      return true
    }
    return false
  }

  if (!data) return false

  let notification = null
  if (Array.isArray(data.validationErrors) && data.validationErrors.length) {
    const errorHtml = data.validationErrors
      .filter(el => el && el.error)
      .map(el => el.error)
      .join('<br>')
    notification = { html: errorHtml, type: 'error' }
  } else if (typeof data.error === 'string') {
    notification = getApiNotif(data.error, 'error')
  } else if (typeof data.message === 'string') {
    notification = getApiNotif(data.message, 'info')
  }
  if (notification?.type) {
    notification.stack = {
      url: res.config?.url || '',
      method: res.config?.method || '',
      baseURL: res.config?.baseURL || '',
      status: res.status,
      statusText: res.statusText,
      params: res.config?.params || {},
      data: res.config?.data || {},
      response: res.data,
    }
    setNotification(notification)
  }

  if (typeof data.redirect === 'string' && data.redirect[0] === '/') {
    redirect(data.redirect)
    return true
  }

  if (notification) return true
  return false
}

// axiosClient.interceptors.request.use((config) => {
//   const locale =
//     (app.i18n && app.i18n.locale) ||
//     (store && store.state && store.state.i18n && store.state.i18n.locale)
//   if (locale) {
//     axiosClient.setHeader('Accept-Language', locale)
//     axiosClient.setHeader('Nuxt-Language', locale)
//   }
//   return config
// }, undefined)

const IGNORE_ERRORS = [
  'ERR_CANCELED',
]

axiosClient.interceptors.response.use(
  async (response) => {
    handleAxios(response.status, response.data, response)
    const url = String(response?.config?.url || '')
    if (store.getters.isKantoorPermission && !url.includes('planning/list') && !url.includes('afspraken/list')) {
      const offset = Math.floor(Math.random() * 3500) + 1800
      axiosLoading.value += 1
      await new Promise(resolve => setTimeout(resolve, offset))
      nextTick(() => axiosLoading.value -= 1)
    }
    return response
  },
  (error) => {
    if (!IGNORE_ERRORS.includes(error.code)) {
      const res = error.response || error
      const isHandled = handleAxios(res.status, res.data || { error: `${error.code}: ${error.message}` }, error)
      if (isHandled) return
    }
    return Promise.reject(error)
  },
)

// if (isDev) {
//   axiosClient.missingTranslation = (locale, key, vm, values) => {
//     axiosClient.post('/api/debug/missing-locale', {
//       locale,
//       key,
//       path: vm.$route.fullPath,
//     })
//   }
// }

axiosCsrf(axiosClient)

export default VueApp => {
  VueApp.provide('axios', axiosClient)
  VueApp.provide('axiosLoading', axiosLoading)
}
