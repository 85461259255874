<template>
  <div>
    <h1 class="text-2xl font-semibold text-gray-900">
      Gebruikers ({{ apiData.is_active ? 'Actief' : 'Archief/Inactief' }})
    </h1>
    <button v-if="apiData.is_active" @click="handleToevoegen">+ Gebruiker toevoegen</button>
    <div class="py-4" :key="apiData.is_active">
      <UITableResponsive :items="items" :headers="headers" :suffix-headers="['Acties']">
        <template v-slot:colgroup>
          <colgroup>
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-0" />
            <col class="w-128" />
            <col class="w-auto" />
          </colgroup>
        </template>
        <template v-slot:item-ID="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:item-Naam="{ item }">{{ [item.firstname, item.lastname].join(' ').trim() }}</template>
        <template v-slot:item-GSM="{ item }">{{ item.phone || '/' }}</template>
        <template v-slot:item-Commisie_%="{ item }">
          {{ item.commisie ? `${item.commisie} %` : '/' }}
        </template>
        <template v-slot:item-Cash="{ item }">
          {{ item.is_cash_toestaan ? 'Toestaan' : 'Vermeiden' }}
        </template>
        <template v-slot:item-Bestuurderspas="{ item }">
          {{ item.bestuurderspas || '?' }}
        </template>
        <template v-slot:item-Type="{ item }">
          {{ item.type || '?' }}
          <small v-if="item.type === 'onderaannemer'" class="block font-bold italic">KBO: {{ item.kbo_nummer || '?' }}</small>
        </template>
        <template v-slot:item-Wagen="{ item }">
          <small class="text-red-500" v-if="!item.wagen_id">Geen wagen toegekend</small>
          <UISelectWagen v-else class="w-full" active disabled v-model="item.wagen_id" />
        </template>
        <template v-slot:item-Acties="{ item }">
          <div class="flex flex-row items-center gap-2">
            <router-link v-if="!store.getters.isKantoorPermission" target="_blank" :to="{ name: item.type === 'onderaannemer' ? 'PlatformUsersWinstmargeOnderaanneming' : 'PlatformUsersRendabiliteit', query: { chauffeur_id: item.id } }" class="btn bg-teal-400 text-white">
              <i class="fas fa-money-check-alt"></i>
            </router-link>
            <button v-if="apiData.is_active && !store.getters.isKantoorPermission" @click="handleBewerken(item)" class="btn warn">Wijzigen</button>
            <button
              class="btn"
              @click="setActivation(item.id)"
              :class="{
                error: apiData.is_active,
                success: !apiData.is_active,
              }"
            >
              {{ apiData.is_active ? 'Desactiveren' : 'Heractiveren' }}
            </button>
          </div>
        </template>
      </UITableResponsive>
      <UITablePagination class="mt-2" :count="count" v-model:limit="limit" v-model:offset="offset" />
    </div>
  </div>
</template>

<script setup>
import { watch, computed, inject, defineAsyncComponent } from 'vue'
import { useRoute } from 'vue-router'
import clone from 'just-clone'

import UISelectWagen from '@/components/UI/Select/Wagen.vue'
import UITableResponsive from '@/components/UI/Table/Responsive'
const UITablePagination = defineAsyncComponent(() => import('@/components/UI/Table/Pagination.vue'))

import usePaginatedApi from '@/hooks/usePaginatedApi'
import useModal from '@/hooks/useModal'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const axios = inject('axios')
const [openFixedModal] = useModal({ fixed: true })

const apiData = computed(() => ({ is_active: !!(route.meta && route.meta.is_active) }))
const { limit, offset, count, items, getData } = usePaginatedApi('/api/dashboard/users/list', apiData, {
  defaultLimit: 50,
})

const setActivation = async id => {
  await axios
    .patch(`/api/dashboard/users/${apiData.value.is_active ? 'deactivate' : 'activate'}`, { id })
    .then(() => getData())
}

const handleToevoegen = () => {
  openFixedModal('FORM_USER', { callback: getData })
}
const handleBewerken = (user) => {
  openFixedModal('FORM_USER', { callback: getData, modelValue: clone(user) })
}

watch(
  () => apiData.value.is_active,
  (v, o) => {
    if (v == o) return
    offset.value = 0
    getData()
  },
)

const headers = computed(() => {
  const result = [
    'ID',
    'Naam',
    store.getters.isKantoorPermission ? undefined : 'Email',
    store.getters.isKantoorPermission ? undefined : 'GSM',
    store.getters.isKantoorPermission ? undefined : 'Bestuurderspas',
    store.getters.isKantoorPermission ? undefined : 'Type',
    store.getters.isKantoorPermission ? undefined : 'Commisie_%',
    store.getters.isKantoorPermission ? undefined : 'Cash',
  ].filter(el => el)

  if (!store.getters.isKantoorPermission && apiData.value.is_active) {
    result.push('Wagen')
  }

  return result
})
</script>
