import {SETTINGS_BEREKENING} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v26/SETTINGS_BEREKENING'

import {SETTINGS_PARKING_KOSTEN} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v25/SETTINGS_PARKING_KOSTEN'

import {SETTINGS_VASTE_ADRES_KOSTEN} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v23/SETTINGS_VASTE_ADRES_KOSTEN'

import {SETTINGS_VASTE_AFSTAND_KOSTEN} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v23/SETTINGS_VASTE_AFSTAND_KOSTEN'

import {berekeningAlgorithm} from 'berekeningen/algorithm'

export const bereken = berekeningAlgorithm(
  SETTINGS_BEREKENING,
  SETTINGS_PARKING_KOSTEN,
  SETTINGS_VASTE_ADRES_KOSTEN,
  SETTINGS_VASTE_AFSTAND_KOSTEN,
)
