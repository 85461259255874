<template>
  <p class="mt-4 mb-8 no-data" v-if="!records.length">Geen ritten gevonden</p>
  <div v-else class="w-full overflow-x-scroll">
    <UIButtonCompact v-model="compact" class="small my-2" />

    <table class="w-full custom-bordered">
      <thead>
        <tr class="whitespace-nowrap text-left">
          <th class="text-center">Rit</th>
          <th class="text-center">Onderaannemer</th>
          <th class="text-center">Rit prijs</th>
          <th class="text-center">Basis prijs onderaannemer</th>
          <th class="text-center">Facturatie onderaannemer</th>
          <th class="text-center">Winstmarge</th>
          <th v-if="!compact">Gegevens</th>
          <th v-if="!compact">Adressen</th>
        </tr>
      </thead>
      <tbody class="whitespace-nowrap">
        <tr
          v-for="(record, recordIndex) in records"
          :key="`${record.uuid}${record.rit?.id}${recordIndex}`"
          class="w-full grid lg:table-row align-top mb-8 card"
        >
          <td class="text-center">
              <router-link
                :to="`/ritten/info/${record.rit.id}`"
                class="btn bg-blue-500 text-white h-8"
              >
                <i class="fas fa-info-circle"></i> {{ record.rit.id }}
              </router-link>
              <span class="px-2 w-20 text-left inline-block">{{ record.rit.is_deel_retour ? 'Retour' : 'Heenreis' }}</span>
          </td>
          <td class="card text-center">{{ store.getters.chauffeur(record.rit.user_id)?.label }}</td>
          <td class="card text-center font-bold">&euro;&nbsp;{{ formatPrice(record.onderaannemerPrijzen.enkeleRitPrijsPlusKorting) }}</td>
          <td class="card text-center font-bold">&euro;&nbsp;{{ formatPrice(record.onderaannemerPrijzen.onderaannemerBasisPrijs) }}</td>
          <td class="card text-center">
            <div class="flex flex-row justify-center items-center gap-2">
              <span class="font-bold">&euro;&nbsp;{{ formatPrice(record.onderaannemerPrijzen.onderaannemerCommissie) }}</span>
              <span>&mdash;</span>
              <span class="italic">{{ record.rit.commisie }}&nbsp;%</span>
            </div>
          </td>
          <td class="card text-center">
            <div class="flex flex-row justify-center items-center gap-2">
              <span class="font-bold">&euro;&nbsp;{{ formatPrice(record.onderaannemerPrijzen.enkeleRitPrijsGevraagd - record.onderaannemerPrijzen.onderaannemerCommissie) }}</span>
              <span>&mdash;</span>
              <span class="italic">{{ formatPrice(record.onderaannemerPrijzen.winstmarge) }}&nbsp;%</span>
            </div>
          </td>
          <td class="card" v-if="!compact">
            <div class="flex flex-col gap-2 my-2">
              <KlantLijn class="card flex-col gray" label="Klant" :klant="record.klant || { firstname: '?' }" />
              <KlantLijn v-if="record.reiziger" class="card flex-col gray" label="Reiziger" :klant="record.reiziger || { firstname: '?' }" />
              <span v-if="record.rit.is_factuur_nodig && !record.facturatie" class="card flex-col rounded">
                <strong>Facturatie:</strong> <span class="text-yellow-500">Factuur nodig, maar geen facturatie gegevens</span>
              </span>
              <FacturatieLijn v-else-if="record.facturatie" class="card flex-col gray" label="Facturatie" :facturatie="record.facturatie" />
            </div>
          </td>

          <td class="card" v-if="!compact">
            <div class="flex flex-col card rounded gray">
              <div>
                <router-link class="hover:underline" target="_blank" :to="{ name: 'Kalender', query: { date: dateString(record.rit.datum) } }">{{ dateTimeStringLocale(record.rit.datum) }}</router-link>
              </div>
              <GoogleAdressenVisueel class="card gray pr-4 my-1" :adressen="record.rit.is_deel_retour ? record.adressen_retour : record.adressen_heen" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { defineProps, defineAsyncComponent, computed, toRefs, ref } from 'vue'
import { dateTimeStringLocale, dateString } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import UIButtonCompact from '@/components/UI/Button/Compact.vue'
import { useOnderaannemerPrijzen } from '@taxiboeken-v2/utilities-omzetten'
import { useStore } from 'vuex'

const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))
const FacturatieLijn = defineAsyncComponent(() => import('@/components/Table/Planning/FacturatieLijn.vue'))
const GoogleAdressenVisueel = defineAsyncComponent(() => import('@/components/Google/AdressenVisueel.vue'))

const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
})

const store = useStore()

const { list } = toRefs(props)
const compact = ref(true)


const RitDataPrijzen = (rit) => {
  return {
    prijs_standaard_heen: rit.prijs_standaard_heen,
    prijs_standaard_retour: rit.prijs_standaard_retour,
    prijs_heen: rit.prijs_heen,
    prijs_retour: rit.prijs_retour,
    korting: rit.korting,
    korting_promotie: rit.korting_promotie,
    korting_afwijking: rit.korting_afwijking,
    is_deel_retour: rit.is_deel_retour,
  }
}

const records = computed(() => {
  return list.value.map(record => {
    return {
      ...record,
      onderaannemerPrijzen: useOnderaannemerPrijzen(RitDataPrijzen(record.rit), record.rit.personen || 0, record.rit.commisie),
    }
  })
})
</script>
