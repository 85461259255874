import type {SettingsBerekeningType} from 'types/Settings'

export const SETTINGS_BEREKENING: Readonly<SettingsBerekeningType> = Object.freeze({
  minimumPrijsEnkeleRit: 65,

  eersteKM: 50,
  prijsEersteKM: 65, // 48
  prijsPerExtraKM: 1.25,

  ritLuchthavenKost: 0,
  lastMinuteKost: 0,
  wakeUpCallKost: 5,
  personenKost: [
    0,
    0,
    0,
    10,
    10,
    10,
    15,
    15,
    'aanvraag', // Laatste enkel op afspraak!
  ] as SettingsBerekeningType['personenKost'],

  priveVervoerKostEnkeleReis: 30,
  priveVervoerSommigeBuitenlandMultiplier: 2,

  luxeExtraKostEnkeleReis: 0,
  luxeMoetPriveVervoerZijn: false,

  gedeeldVervoerOpslag: 0,
  opslag: 0,
  extraBagageOpslag: 0,
  volledigBuitenlandOpslag: 0,
})
