<template>
  <div v-if="!loadingRit && !data?.rit?.id">
    Rit niet gevonden
  </div>
  <div v-else class="flex flex-col gap-2">
    <h1>
      Rit #{{ route.params.rit_id }}
    </h1>

    <UITabs class-button="block mb-4" class="flex w-full" :tabs="tabs" v-model="tab" v-if="tabs.length > 1"/>

    <KeepAlive :key="data?.rit?.id || 'no-rit'">
      <template v-if="tabLabel == 'Passagiers' && store.getters.hasAdminPermission">
        <h2 class="text-2xl font-semibold text-gray-900">
          Passagier lijst/checklist (voor groepen)
        </h2>
        <TableRittenPassagiers :rit_id="data?.rit?.id || null" acties />
      </template>
      <template v-else-if="tabLabel == 'Mails' && store.getters.hasAdminPermission">
        <h2 class="text-2xl font-semibold text-gray-900">
          Mails via mailsysteem voor deze reservatie
        </h2>
        <TableMails :rit_id="data?.rit?.id || null" />
      </template>
      <template v-else-if="data?.rit">
        <div :class="alertbox.alertClass" class="p-4 rounded border flex flex-row items-center justify-between gap-2 font-bold">
          <p v-html="alertbox.alert" class="m-0"></p>
          <button v-if="alertbox.button" class="btn" :class="alertbox.buttonClass" @click.prevent="alertbox.handler">
            {{ alertbox.button }}
          </button>
        </div>

        <div v-if="store.getters.hasMasterPermission && data.rit" class="card rounded bg-red-100 border-red-300 my-2 flex flex-row gap-4 items-center font-bold">
          <button class="btn error" @click="onHandleRitX(data.rit)">Rit definitief verwijderen</button>
          <span class="text-red-800">Opgelet deze actie is onomkeerbaar!</span>
        </div>

        <div class="card rounded flex flex-col gap-2 mt-4" :class="{ 'border-2 border-black': data.blacklisted }" >
          <LabelBlacklisted :value="data.blacklisted" class="self-start" />
          <KlantLijn label="Klant" :klant="data.klant" />
          <KlantLijn v-if="data.reiziger" label="Reiziger" :klant="data.reiziger" />
          <div class="flex gap-2" v-show="data.rit.persona || data.rit.opmerking">
            <ButtonAlertPersona :persona="data.rit.persona" />
            <ButtonAlertOpmerking v-show="data.rit.opmerking" :opmerking="data.rit.opmerking" />
          </div>
        </div>

        <div class="card rounded">
          <div class="grid xl:grid-cols-2 gap-2">
            <div>
              <TableRitInfo v-show="tab === 0" :info.sync="data"></TableRitInfo>
              <TableRitInfo v-show="tab === 1" v-if="heeftRetour" is-retour :info.sync="data"></TableRitInfo>
              <div class="text-right pt-1 pr-2 text-xs flex flex-col items-start italic">
                <span class="self-end">
                  <strong>Aangemaakt op</strong>: {{ dateTimeStringLocale(data.rit.creation_date) }}
                </span>
                <div class="flex flex-row gap-1 md:-mt-5 mb-2">
                  <span v-if="data.rit.is_offerte" class="label-base bg-blue-500 text-white">
                    {{ data.rit.is_offerte_ingevuld ? 'Van Offerte' : 'Open Offerte' }}
                  </span>
                  <RitBronDomein class="mr-1" :bron="data.rit.bron" :domein="data.rit.domein" :bedrijf_thema="data.rit.bedrijf_thema" />
                  <RitPromotie :promotie="data.rit.promotie_code" hide-empty />
                </div>
              </div>

              <div class="flex flex-col md:flex-row gap-2" v-if="store.getters.hasKantoorPermission">
                <router-link class="btn info self-start" :to="`/ritten/kalender?date=${dateString(toonRetour ? data.rit.retour_datetime : data.rit.vertrek_datetime)}`">
                  Open kalender {{ dateTimeString(toonRetour ? data.rit.retour_datetime : data.rit.vertrek_datetime) }}
                </router-link>
                <router-link class="btn warning self-start" :to="`/ritten/bewerken/${data.rit.id}?from=${route.fullPath}`">
                  Rit bewerken
                </router-link>
              </div>
            </div>

            <div v-if="store.getters.hasKantoorPermission"  class="flex flex-col gap-2 items-start">
              <div class="w-full">
                <span class="font-bold block mb-2">Chauffeurs</span>
                <ChauffeurLijst
                  class="block card rounded w-full"
                  :toon-wagen="store.getters.hasAdminPermission"
                  :toon-omzet="store.getters.hasAdminPermission"
                  :toon-shiften="store.getters.hasKantoorPermission"
                  list-class="card"
                  :chauffeurs="(toonRetour ? data.chauffeurs_retour : data.chauffeurs_heen) || []"
                />
              </div>

              <div class="w-full">
                <span class="font-bold block">Feedback</span>
                <TableFeedback
                  class="w-full p-1"
                  :items="(toonRetour ? data.feedback_retour : data.feedback_heen) || []"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card grid lg:grid-cols-2 gap-2">
          <div class="flex flex-col gap-4">
            <div class="card">
              <h2>Facturatie</h2>
              <span v-if="!store.getters.hasKantoorPermission && (data.rit.is_factuur_nodig || data.facturatie)">
                <span class="text-yellow-500">Factuur zal opgemaakt worden voor de klant.</span>
              </span>
              <span v-else-if="data.rit.is_factuur_nodig && !data.facturatie">
                <span class="text-yellow-500">Factuur nodig, maar geen facturatie gegevens</span>
              </span>
              <FacturatieLijn v-else-if="data.facturatie" label="Facturatie" :facturatie="data.facturatie" />
              <div v-else class="no-data">Geen factuur nodig</div>
            </div>

            <div class="card" v-if="data.rit">
              <h2>Reservatie cijfers</h2>

              <ul class="flex flex-col gap-1">
                <li>
                  <UITextRitInfoCijfer label="Totaalprijs" :value="data.rit.prijs">
                    <small>(incl. {{ data.rit.btw_percentage }}% BTW)</small>
                  </UITextRitInfoCijfer>
                </li>
              </ul>

              <hr v-if="store.getters.hasKantoorPermission" class="my-2" />

              <div class="w-full flex flex-row gap-6">
                <ul v-if="store.getters.hasKantoorPermission" class="flex flex-col gap-1">
                  <li>
                    <UITextRitInfoCijfer label="Korting gegeven" :value="data.rit.korting" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Korting via waardebon/voucher" :value="data.rit.korting_promotie">
                      <small v-if="data.rit.promotie_code">({{ data.rit.promotie_code }})</small>
                    </UITextRitInfoCijfer>
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Korting via afwijking" :value="data.rit.korting_afwijking">
                      <small>({{ secondsToHMSWritten(data.rit.afwijking_minuten * 60) }})</small>
                    </UITextRitInfoCijfer>
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Extra kost aangerekend" :value="data.rit.extra_kost" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Prijs verhoging aangerekend" :value="data.rit.prijs_verhoging" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost personen" :is-retour="data.rit.is_retour" :heen="data.rit.kost_personen_heen" :retour="data.rit.kost_personen_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost privé vervoer" :is-retour="data.rit.is_retour" :heen="data.rit.kost_prive_vervoer / retourCoeff" :retour="data.rit.kost_prive_vervoer / retourCoeff" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost luxe vervoer" :is-retour="data.rit.kost_luxe_vervoer" :heen="data.rit.kost_luxe_vervoer / retourCoeff" :retour="data.rit.kost_luxe_vervoer / retourCoeff" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost last minute" :value="data.rit.kost_last_minute" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost wake-up call" :value="data.rit.kost_wakeup_call" />
                  </li>
                </ul>

                <ul v-if="store.getters.hasKantoorPermission" class="flex flex-col gap-1">
                  <li>
                    <UITextRitInfoCijfer label="Kost volledig buitenlandse opdracht" :value="data.rit.kost_volledig_buitenland" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost enkele rit van luchthaven" :value="data.rit.kost_enkel_luchthaven" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost bepaalde vaste afstand" :is-retour="data.rit.is_retour" :heen="data.rit.kost_vaste_afstand_heen" :retour="data.rit.kost_vaste_afstand_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost tol" :is-retour="data.rit.is_retour" :heen="data.rit.kost_tol_heen" :retour="data.rit.kost_tol_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost parking" :is-retour="data.rit.is_retour" :heen="data.rit.kost_parking_heen" :retour="data.rit.kost_parking_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost algemene opslag" :is-retour="data.rit.is_retour" :heen="data.rit.kost_algemene_opslag_heen" :retour="data.rit.kost_algemene_opslag_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost opslag gedeeld vervoer" :is-retour="data.rit.is_retour" :heen="data.rit.kost_gedeeld_vervoer_opslag_heen" :retour="data.rit.kost_gedeeld_vervoer_opslag_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost extra handbagage" :is-retour="data.rit.is_retour" :heen="data.rit.kost_extra_handbagage_heen" :retour="data.rit.kost_extra_handbagage_retour" />
                  </li>
                  <li>
                    <UITextRitInfoCijfer label="Kost extra grote bagage" :is-retour="data.rit.is_retour" :heen="data.rit.kost_extra_bagage_heen" :retour="data.rit.kost_extra_bagage_retour" />
                  </li>
                </ul>
              </div>

            </div>
          </div>
          <div class="card rounded">
            <div class="title justify-start">
              <div class="flex flex-col">
                <h2>Betalingen</h2>
                <ul class="text-xs font-normal">
                  <li><strong>Betaalmethode</strong>: {{ useBetaalmethodeVanRit(data.rit) }}</li>
                  <li><strong>Open saldo</strong>: {{ formatPrice(openSaldo) }} EUR.</li>
                  <UIButtonRitBetaling :disabled="openSaldo <= 0" :rit="data.rit" @refresh="getData" class="mt-1">Betaling toevoegen</UIButtonRitBetaling>
                </ul>
              </div>
              <ul class="text-xs font-normal">
                <li><strong>Totaal betaald</strong>:  {{ formatPrice(totaalBetaald) }} EUR.</li>
                <li v-if="store.getters.hasKantoorPermission"><strong>Totaal terugbetaald</strong>: {{ formatPrice(totaalTerugbetaald) }} EUR.</li>
                <li v-if="store.getters.hasKantoorPermission"><strong>Afgerekend cash</strong>:  {{ formatPrice(totaalAfgerekendCash) }} / {{ formatPrice(totaalCash) }} EUR.</li>
              </ul>
            </div>
            <div class="no-data" v-if="!data.betalingen?.length">Geen betalingen</div>
            <BetalingLijn class="card block" v-for="(betaling, index) in data.betalingen" :key="betaling.id" v-model:betaling="data.betalingen[index]" @delete="getData" />
            <button
              v-if="!data.rit.is_geannuleerd && store.getters.hasAdminPermission"
              @click="handleCreditCard(data.rit, !data.rit.is_creditcard_toelaten)"
              :disabled="openSaldo <= 0"
              class="btn shadow-none text-sm font-bold rounded-sm enabled-style mt-6"
              :class="{
                'bg-gray-200 text-gray-500': !data.rit.is_creditcard_toelaten,
                'bg-green-500 text-white': data.rit.is_creditcard_toelaten,
              }"
            >
              <i
                class="text-xs fas"
                :class="{ 'fa-times': !data.rit.is_creditcard_toelaten, 'fa-check': data.rit.is_creditcard_toelaten }"
              ></i>
              Credit Card Toelaten
            </button>
          </div>
        </div>

      </template>
    </KeepAlive>
  </div>
</template>

<script setup>
import { defineAsyncComponent, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { dateString, dateTimeString, dateTimeStringLocale } from '@/functions/formatDate'
import { formatPrice } from '@/functions/formatNumber'
import useGetApi from '@/hooks/useGetApi'
import useModal from '@/hooks/useModal'

import FacturatieLijn from '@/components/Table/Planning/FacturatieLijn.vue'
import ChauffeurLijst from '@/components/UI/List/ChauffeurLijst.vue'
import TableFeedback from '@/components/Table/Feedback/List.vue'
import TableRitInfo from '@/components/Table/Ritten/Info.vue'
import TableRittenPassagiers from '@/components/Table/Ritten/Passagiers.vue'
import UITabs from '@/components/UI/Tabs.vue'
import UITextRitInfoCijfer from '@/components/UI/Text/RitInfoCijfer.vue'
import BetalingLijn from '@/components/UI/List/BetalingLijn.vue'
import UIButtonRitBetaling from '@/components/UI/Button/RitBetaling.vue'

import RitBronDomein from '@/components/UI/Labels/RitBronDomein.vue'
import RitPromotie from '@/components/UI/Labels/RitPromotie.vue'
import useBetaalmethodeVanRit from '@/hooks/useBetaalmethodeVanRit'
import { handleRitProp } from '@/functions/apiHandleRitProp'
import { secondsToHMSWritten } from '@/functions/formatDate'

const TableMails = defineAsyncComponent(() => import('@/components/Table/Mails/Index.vue'))

const route = useRoute()
const router = useRouter()
const store = useStore()

const ButtonAlertOpmerking = defineAsyncComponent(() => import('@/components/UI/Button/AlertOpmerking.vue'))
const ButtonAlertPersona = defineAsyncComponent(() => import('@/components/UI/Button/AlertPersona.vue'))
const KlantLijn = defineAsyncComponent(() => import('@/components/Table/Planning/KlantLijn.vue'))
const LabelBlacklisted = defineAsyncComponent(() => import('@/components/UI/Labels/Blacklisted.vue'))

const { data, getData, loading: loadingRit } = useGetApi('RIT_INFO', { rit_id: route.params.rit_id }, { watch: true })

const heeftRetour = computed(() => data.value?.rit?.is_retour)
const tabs = computed(() => [{ label: 'Heenreis' }, { label: 'Retour', hidden: !heeftRetour.value }, { label: 'Mails', hidden: !store.getters.hasAdminPermission }, { label: 'Passagiers', hidden: !store.getters.hasAdminPermission }].filter(el => !el.hidden))
const tab = ref(route.query?.deel?.toLowerCase() === 'retour' && tabs.value.length > 1 ? 1 : 0)
const tabLabel = computed(() => tabs.value[tab.value].label)
const toonRetour = computed(() => tab.value === 1)

const openSaldo = computed(() => data?.value?.rit?.bedrag_open_saldo || 0)

const [openFixedModal] = useModal({ fixed: true })

const onHandleAnnuleer = (rit) => {
  openFixedModal('FORM_RIT_ANNULEER', { rit, uit_onbevestigden: false, callback: getData })
}

const onHandleBevestig = (rit) => {
  openFixedModal('FORM_RIT_BEVESTIG', { rit, uit_onbevestigden: false, callback: getData })
}

const onHandleRitX = (rit) => {
  const callback = () => {
    router.push({ name: 'Home' })
  }
  openFixedModal('FORM_RIT_X', {
    id: rit.id,
    callback,
  })
}

const retourCoeff = computed(() => data.value.rit?.is_retour ? 2 : 1)

const betalingen = computed(() => data.value?.betalingen || [])
const totaalBetaald = computed(() => betalingen.value.reduce((res, betaling) => res + Math.max(0, betaling.bedrag || 0), 0))
const totaalTerugbetaald = computed(() => betalingen.value.reduce((res, betaling) => res + Math.max(0, betaling.bedrag_terugbetaald || 0), 0))
const totaalCash = computed(() => betalingen.value.reduce((res, betaling) => {
  if (betaling.methode !== 'CASH') return res
  return res + Math.max(0, betaling.bedrag || 0)
}, 0))
const totaalAfgerekendCash = computed(() => betalingen.value.reduce((res, betaling) => {
  if (!betaling.is_afgerekend || betaling.methode !== 'CASH') return res
  return res + Math.max(0, betaling.bedrag || 0)
}, 0))

const alertbox = computed(() => {
  const rit = data.value?.rit || {}
  if (rit.is_bevestigd) {
    return {
      alert: 'Rit momenteel bevestigd',
      alertClass: 'bg-green-100 border-green-300',
      button: store.getters.hasKantoorPermission ? 'Rit annuleren' : undefined,
      buttonClass: 'error',
      handler: () => onHandleAnnuleer(rit),
    }
  }
  if (rit.is_geannuleerd) {
    return {
      alert: `Rit momenteel geannuleerd (${formatPrice(rit.kosten_annulatie) || 0} EUR. annulatiekosten)`.trim(),
      alertClass: 'bg-red-100 border-red-300 text-red-700',
      button: store.getters.hasKantoorPermission ? 'Rit bevestigen' : undefined,
      buttonClass: 'success',
      handler: () => onHandleBevestig(rit),
    }
  }
  return {
      alert: [
        'Deze rit is nog niet door ons bureau behandeld.',
        'Deze rit zal zo snel mogelijk bevestigd of geannuleerd worden.',
        'Wij houden je op de hoogte.',
      ].join('<br>'),
      alertClass: 'bg-blue-100 border-blue-300',
  }
})

const handleCreditCard = (rit, newValue) =>
  handleRitProp(
    rit,
    newValue,
    'is_creditcard_toelaten',
    'is_creditcard_toelaten',
  )
</script>
