/* eslint-disable @typescript-eslint/no-explicit-any */

import {parseAdressen} from 'berekeningen/utils/parseAdressen'
import type {BerekeningsContextType} from 'types/Berekening'
import type {SettingsBerekeningType, SettingsVasteAdresKostenType} from 'types/Settings'

export const getMinimumPrijs = (
  adressenParam: any[],
  BERKENINGS_CONTEXT: BerekeningsContextType,
  SETTINGS_BEREKENING: SettingsBerekeningType,
  SETTINGS_VASTE_ADRES_KOSTEN: SettingsVasteAdresKostenType,
) => {
  const {is_prive_vervoer, is_budget_vervoer, is_luxe_vervoer, is_retour} = BERKENINGS_CONTEXT

  const adressen = parseAdressen(adressenParam)
  if (!adressen.length) {
    console.warn('Berekening zonder opgegeven adressen in params!', {adressenParam})
  }

  let result = adressen.reduce(
    (n: number, adres: string) => {
      if (SETTINGS_VASTE_ADRES_KOSTEN[adres] && SETTINGS_VASTE_ADRES_KOSTEN[adres] > n) {
        return SETTINGS_VASTE_ADRES_KOSTEN[adres]
      }

      const partialMatchValue = Object.entries(SETTINGS_VASTE_ADRES_KOSTEN).reduce((prev, [key, val]) => {
        if (adres.includes(key) && val > prev) {
          return val
        }
        return prev
      }, 0)

      return partialMatchValue > n ? partialMatchValue : n
    },
    Math.max(SETTINGS_BEREKENING.minimumPrijsEnkeleRit, 0),
  )

  const coeff = is_retour ? 2 : 1
  if (!is_budget_vervoer) {
    result -= SETTINGS_BEREKENING.opslag / coeff
  } else if (!is_budget_vervoer && !is_prive_vervoer && !is_luxe_vervoer) {
    result -= SETTINGS_BEREKENING.gedeeldVervoerOpslag / coeff
  }

  return Math.round(Math.max(0, result))
}
