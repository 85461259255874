<template>
  <UISelectModalNullable
    v-bind="$attrs"
    v-model="innerValue"
    :label="label === false ? '' : (typeof label === 'string' ? label : 'Chauffeur')"
    :options="options"
    :placeholder="placeholder"
    search-placeholder="Chauffeur zoeken..."
  />
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits, inject, onMounted, toValue } from 'vue'
import { useStore } from 'vuex'
import clone from 'just-clone'

import UISelectModalNullable from '@/components/UI/Select/SelectModalNullable.vue'
import useRouteQueryInt from '@/hooks/useRouteQueryInt'

const props = defineProps({
  label: [Boolean, String],
  useQuery: Boolean,
  skipMounted: Boolean,
  prefixItems: Array,
  suffixItems: Array,
  formatLabel: Function,
  mapChauffeur: Function,
  filterChauffeurs: Function,
  datum: [Date, String, Number],
  active: {
    type: Boolean,
    default: undefined,
  },
  disableAfwezig: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: 'Selecteer een chauffeur',
  },
  modelValue: {
    type: [String, Number],
    default: null,
  },
})

const store = useStore()
const emit = defineEmits(['update:modelValue'])

const options = computed(() => {
  const id = Number(props.modelValue || -1)
  let array = clone(store.state.chauffeurs || [])
  if (typeof props.active !== 'undefined') {
    // Enkel actieve of reeds geselecteerde!
    array = array.filter(item => item.is_active == props.active || item.value == id || item.id == id)
  }
  if (typeof props.filterChauffeurs === 'function') {
    array = array.filter(props.filterChauffeurs)
  }
  if (props.prefixItems?.length) {
    array = [].concat(props.prefixItems, array)
  }
  if (props.suffixItems?.length) {
    array = [].concat(array, props.suffixItems)
  }
  return clone(array).map(formatChauffeur.value)
})

const safeModelValue = () => {
  if (props.modelValue > 0) innerValue.value = Number(props.modelValue)
  if (innerValue.value === '' || isNaN(innerValue.value)) innerValue.value = null
}

const innerValue = props.useQuery ? useRouteQueryInt('chauffeur', null) : ref(props.modelValue || null)
if (!props.useQuery) {
  onMounted(() => {
    watch(() => props.modelValue, () => {
      innerValue.value = props.modelValue || null
    })
  })
}
safeModelValue()

const emitValue = (val) => emit('update:modelValue', val)
watch(() => innerValue.value, (val, oldval) => {
  if (val === oldval) return
  emitValue(val)
})
if (!props.skipMounted) emitValue(innerValue.value)


/**
 * Chauffeur option formatting
 */
const mapChauffeurAfwezigheden = (chauffeur, afwezighedenDTO) => {
  if (!props.datum || !afwezighedenDTO) return chauffeur

  let found = false

  const list = toValue(afwezighedenDTO)?.list || []
  if (list.length) {
    const afwezigheid = list.find((x) => x.user_id == chauffeur.value)
    if (afwezigheid?.start && afwezigheid?.einde) {
      const start = new Date(afwezigheid.start)
      start.setSeconds(0)
      const d = new Date(props.datum)
      d.setSeconds(1)
      const einde = new Date(afwezigheid.einde)
      einde.setSeconds(59)
      found = !!(d.getTime() >= start.getTime() && d.getTime() <= einde.getTime())
    }
  }


  if (found) {
    chauffeur.disabled = !!props.disableAfwezig
    chauffeur.label = `📆 ${chauffeur.label || ''}`
  }

  return chauffeur
}

const mapChauffeurGeenCash = (chauffeur) => {
  if (props.isTeOntvangen && chauffeur.is_cash_toestaan === false) {
    // chauffeur.disabled = true
    chauffeur.label = `❌💵 ${chauffeur.label || ''}`
  }

  return chauffeur
}

const mapChauffeurToegekend = (chauffeur, bevestigdeRittenDTO) => {
  if (!chauffeur?.value || !bevestigdeRittenDTO) {
    return chauffeur
  }

  const bevestigdeRittenLijst = toValue(bevestigdeRittenDTO) || []

  if (chauffeur.value === -1) {
    const aantalRittenNietToegekend = bevestigdeRittenLijst.reduce((prev, next) => {
      if (!next?.chauffeurs?.length || !next?.chauffeurs?.find(c => c.chauffeur_id > 0)) {
        return prev + 1
      }
      return prev
    }, 0)
    chauffeur.label = `(${aantalRittenNietToegekend}) ${chauffeur.label || ''}`.trim()
    return chauffeur
  }

  const aantalRitten = bevestigdeRittenLijst.reduce((prev, next) => {
    if (next?.chauffeurs?.find(c => c.chauffeur_id === chauffeur.value)) {
      return prev + 1
    }
    return prev
  }, 0)

  chauffeur.label = `(${aantalRitten}) ${chauffeur.label || ''}`.trim()
  return chauffeur
}

/**
 * Computed because map in options need to be reactive based on this function parameters
 */
const { bevestigdeRitten, afwezigheden } = inject('kalender', { bevestigdeRitten: undefined, afwezigheden: undefined })

const formatChauffeur = computed(() => (chauffeurDTO) => {
  let chauffeur = chauffeurDTO

  if (typeof props.formatLabel === 'function') {
    chauffeur.label = props.formatLabel(chauffeur.label || '', chauffeur)
  }

  if (typeof props.mapChauffeur === 'function') {
    chauffeur = props.mapChauffeur(chauffeur)
  }

  chauffeur = mapChauffeurAfwezigheden(chauffeur, afwezigheden)
  chauffeur = mapChauffeurGeenCash(chauffeur)
  chauffeur = mapChauffeurToegekend(chauffeur, bevestigdeRitten)

  return chauffeur
})
</script>
