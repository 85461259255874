/* eslint-disable @typescript-eslint/no-explicit-any */
// @TODO: Any types should be replaced with a proper type.

// Voor setup waarbij strings worden meegegeven in adressen properties ipv. google objects.
const getLandFromAdres = (str: any) => (typeof str === 'string' ? str : '').trim().split(' ').slice(-1)[0]

const getCountries = (adressen: any) => {
  return (adressen || []).map((el: any) => {
    if (!el) return ''
    const found = (
      el.country
      || (el.google && el.google.country)
      || (el.google && el.google.seperated && el.google.seperated.country)
      || getLandFromAdres(el.adres)
      || getLandFromAdres(el)
      || ''
    )
    return typeof found === 'string' ? found.toUpperCase() : ''
  }).filter((el: string | any[]) => el.length > 0)
}

export const isSommigeBuitenland = (adressen: any[]) => {
  const landen = getCountries(adressen)
  const nietBelgie = landen.slice(0).filter((land: string | string[]) => land != 'BE' && land.indexOf('BELG') !== 0)
  return nietBelgie.length > 0
}

export const isVolledigBuitenland = (adressen: any[]) => {
  const landen = getCountries(adressen)
  const nietBelgie = landen.filter((land: string | string[]) => land != 'BE' && land.indexOf('BELG') !== 0)
  return !!(nietBelgie.length && landen.length && nietBelgie.length === landen.length)
}
