// TODO: This can be improved by using a proper type for _adressen
// But for now, we'll just use any for backwards compatibility with all systems.

// TODO: Moesten we dit veranderen, kijk dan usage van parseAdressen na in de codebase.
// En wijzig dan any[] type naar geschikte aangepast type.

export const parseAdressen = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _adressen: any[] = [],
): string[] => {
  return (_adressen || []).map((el) => {
    const adres: unknown = el?.adres || el || ''

    if (typeof adres === 'string') return adres

    if (typeof adres === 'object') {
      const obj: Record<string, unknown> = adres as Record<string, unknown>
      return String(obj?.adres || obj?.value || '')
    }

    return String(adres || '')
  }).filter((el) => el)
}
