import type {SettingsVasteAdresKostenType} from 'types/Settings'

export const SETTINGS_VASTE_ADRES_KOSTEN: Readonly<SettingsVasteAdresKostenType> = Object.freeze({
  Schiphol: 220,
  'Airport Schiphol': 220,
  'Schiphol, Nederland': 220,
  'Amsterdam Airport Schiphol (AMS), Evert van de Beekstraat 202, 1118 CP Schiphol, Nederland': 220,

  // 350 - 40 tolkosten
  'Parijs-Charles de Gaulle': 310,
  'Luchthaven Parijs-Charles de Gaulle (CDG), 95700 Roissy-en-France, Frankrijk': 310,

  // 380 - 40 tolkosten
  'Luchthaven Orly': 340,
  'Luchthaven Orly (ORY), 94390 Orly, Frankrijk': 340,

  'Eindhoven, Nederland': 135,
  'Luchthavenweg 25, 5657 EA Eindhoven, Nederland': 135,

  '59810 Lesquin, Frankrijk': 115,
  "Rte de l'Aéroport, 59810 Lesquin, Frankrijk": 115,

  '59777 Lille': 110,

  '40474 Düsseldorf': 225,
})
