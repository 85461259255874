import {isLastMinute} from 'functions/isLastMinute'

import type {BerekeningsContextType, BerekeningsParamsDTOType} from 'types/Berekening'

export const getBerekeningsContext = (
  params: BerekeningsParamsDTOType,
  overwrites?: Partial<BerekeningsContextType>,
): Readonly<BerekeningsContextType> => {
  if (!params || typeof params !== 'object')
    throw new Error('Verkeerde of geen context meegegeven aan BerekeningsContext.')

  const result = {
    adressen_heen: params.adressen_heen || [],
    adressen_retour: !params.is_retour ? [] : params.adressen_retour || [],

    is_retour: !!params.is_retour,

    kilometers_heen: Math.max(Number(params.kilometers_heen || 0), 0),
    personen_heen: Math.max(Number(params.personen_heen || 1), 1),
    bagage_heen: Math.max(Number(params.bagage_heen || 0), 0),
    handbagage_heen: Math.max(Number(params.handbagage_heen || 0), 0),
    kost_tol_heen: Math.max(Number(params.kost_tol_heen || 0), 0),

    kilometers_retour: !params.is_retour ? 0 : Math.max(Number(params.kilometers_retour || 0), 0),
    personen_retour: !params.is_retour ? 0 : Math.max(Number(params.personen_retour || 1), 1),
    bagage_retour: !params.is_retour ? 0 : Math.max(Number(params.bagage_retour || 0), 0),
    handbagage_retour: !params.is_retour ? 0 : Math.max(Number(params.handbagage_retour || 0), 0),
    kost_tol_retour: !params.is_retour ? 0 : Math.max(Number(params.kost_tol_retour || 0), 0),

    is_ophaal_luchthaven: !!params.is_ophaal_luchthaven,
    is_bestemming_luchthaven: !!params.is_bestemming_luchthaven,
    is_wakeup_call: !!params.is_wakeup_call,

    is_budget_vervoer: false,
    is_gedeeld_vervoer: false,
    is_prive_vervoer: !!(params.is_prive_vervoer || Number(params.kost_prive_vervoer ?? -1) > 0),
    is_luxe_vervoer: !!(params.is_luxe_vervoer || Number(params.kost_luxe_vervoer ?? -1) > 0),

    is_last_minute: !!(params.is_last_minute || (params.vertek_datetime && isLastMinute(params.vertek_datetime))),

    btw_percentage: Math.max(
      typeof params.btw_percentage === 'number' || Number(params.btw_percentage ?? -1) >= 0
        ? Math.max(0, Number(params.btw_percentage))
        : 6,
      0,
    ),

    korting: Math.max(Number(params.korting || 0), 0),
    korting_promotie: Math.max(Number(params.korting_promotie || 0), 0),
    korting_afwijking: Math.max(Number(params.korting_afwijking || 0), 0),

    extra_kost: Math.max(Number(params.extra_kost || 0), 0),
    prijs_verhoging: Math.max(Number(params.prijs_verhoging || 0), 0),
  } as BerekeningsContextType

  result.is_gedeeld_vervoer = !result.is_prive_vervoer && !result.is_luxe_vervoer && !result.is_budget_vervoer

  return Object.freeze({
    ...result,
    ...overwrites,
  })
}
