import {AdresTypeEnum} from 'enums/Adres'
import {GoogleMapsPlaceSeperatedEnum} from 'enums/Google'
import type {
  GoogleMapsCustomAdres,
  GoogleMapsCustomAdresSeperated,
  GoogleMapsDirectionsRouteResponseType,
  GoogleMapsPlace,
  GooglePlaceAddressComponent,
} from 'types/Google'

const ALLOWED_GOOGLE_MAPS_SEPERATED_TYPES: string[] = Object.values(GoogleMapsPlaceSeperatedEnum)

export const parseGoogleMapsTypes = (place?: GoogleMapsPlace | google.maps.places.PlaceResult) => {
  if (!place?.types?.length) return []
  return place.types.map(type => String(type).toLowerCase())
}

export const parseGoogleMapsSeperated = (
  place?: GoogleMapsPlace | google.maps.places.PlaceResult,
): GoogleMapsCustomAdres['seperated'] => {
  if (!place?.address_components) return undefined

  // @ts-ignore
  return place?.address_components?.reduce(
    (result: GoogleMapsCustomAdresSeperated, part: GooglePlaceAddressComponent) => {
      const value = part.long_name || part.short_name || ''
      const type = part.types?.find(t => ALLOWED_GOOGLE_MAPS_SEPERATED_TYPES.includes(String(t).toLowerCase()))

      if (type && value) {
        result[type as keyof GoogleMapsCustomAdresSeperated] = String(value)
      }

      return result
    },
    {
      [GoogleMapsPlaceSeperatedEnum.ROUTE]: '',
      [GoogleMapsPlaceSeperatedEnum.POSTAL_CODE]: '',
      [GoogleMapsPlaceSeperatedEnum.LOCALITY]: '',
      [GoogleMapsPlaceSeperatedEnum.COUNTRY]: '',
    } as GoogleMapsCustomAdresSeperated,
  )
}

export const parseGoogleMapsIsLuchthaven = (place?: GoogleMapsPlace | google.maps.places.PlaceResult): boolean => {
  if (!place) return false
  const types = parseGoogleMapsTypes(place)

  if (!types?.length) return false

  if (types.includes('airport')) return true

  const namedPlace = place?.name?.toLowerCase()
  const nameIsLuchthaven =
    namedPlace?.includes('airport') || namedPlace?.includes('aéroport') || namedPlace?.includes('luchthaven') || false

  if (types.includes('transit_station') && nameIsLuchthaven) return true

  return false
}

export const parseGoogleMapsIsStation = (place?: GoogleMapsPlace | google.maps.places.PlaceResult): boolean => {
  if (!place) return false
  const types = parseGoogleMapsTypes(place)

  if (!types?.length) return false

  let POSSIBLE_TYPES = ['transit_station', 'bus_station', 'subway_station', 'light_rail_station']
  if (types.find(type => POSSIBLE_TYPES.includes(type))) {
    return true
  }

  POSSIBLE_TYPES = ['point_of_interest', 'establishment']
  if (types.find(type => POSSIBLE_TYPES.includes(type))) {
    const namedPlace = place?.name?.toLowerCase()
    const nameIsStation =
      namedPlace?.startsWith('station ') ||
      namedPlace?.startsWith('treinstation ') ||
      namedPlace?.startsWith('centraal station') ||
      namedPlace?.startsWith('gare ') ||
      namedPlace?.startsWith('gare central') ||
      namedPlace?.endsWith(' station') ||
      namedPlace?.endsWith(' treinstation') ||
      namedPlace?.endsWith('centraal station') ||
      namedPlace?.endsWith(' gare') ||
      namedPlace?.endsWith(' gare central') ||
      false

    return nameIsStation
  }

  return false
}

export const parseGoogleMapsIsHaven = (place?: GoogleMapsPlace | google.maps.places.PlaceResult): boolean => {
  if (!place) return false
  const types = parseGoogleMapsTypes(place)

  if (!types?.length) return false

  const namedPlace = place?.name?.toLowerCase()
  const nameIsHaven =
    namedPlace?.startsWith('port of') ||
    namedPlace?.startsWith('port de') ||
    namedPlace?.startsWith('haven van') ||
    namedPlace?.startsWith('zeehaven van') ||
    false

  return nameIsHaven
}

export const parseGoogleMapsCustomAdres = (place?: google.maps.places.PlaceResult): GoogleMapsCustomAdres => {
  const result = {
    place_id: String(place?.place_id || '') || undefined,
    adres: String(place?.formatted_address || ''),
    name: String(place?.name || '') || undefined,
    lng: place?.geometry?.location?.lng() || undefined,
    lat: place?.geometry?.location?.lat() || undefined,
    seperated: parseGoogleMapsSeperated(place),
    adresType: AdresTypeEnum.ADRES,
  } as GoogleMapsCustomAdres

  if (result.seperated?.country && result.adres && !result.adres.includes(result.seperated.country)) {
    result.adres += `, ${result.seperated.country}`
  }

  if (parseGoogleMapsIsLuchthaven(place)) result.adresType = AdresTypeEnum.LUCHTHAVEN
  if (parseGoogleMapsIsStation(place)) result.adresType = AdresTypeEnum.STATION
  if (parseGoogleMapsIsHaven(place)) result.adresType = AdresTypeEnum.HAVEN

  if (place?.name && result.adresType !== AdresTypeEnum.ADRES) {
    result.adres = `${place.name}, ${result.adres}`
  }

  return result
}

export const GenerateCustomAdresEmpty = (overwriteValues?: Partial<GoogleMapsCustomAdres>): GoogleMapsCustomAdres =>
  ({
    place_id: undefined,
    adres: '',
    name: '',
    lng: undefined,
    lat: undefined,
    seperated: undefined,
    adresType: AdresTypeEnum.ADRES,
    ...(overwriteValues || {}),
  }) as GoogleMapsCustomAdres

export const getGoogleMapsDirectionsRouteResponse = (
  directionRoutes: google.maps.DirectionsResult['routes'],
  opts: {basedOn: 'TIME' | 'DISTANCE'} = {basedOn: 'DISTANCE'},
): GoogleMapsDirectionsRouteResponseType => {
  let route: google.maps.DirectionsRoute | undefined = undefined
  let meters = 0
  let time = 0

  directionRoutes.forEach(directionRoute => {
    const routeMeters = directionRoute.legs.reduce((sum, leg) => {
      return sum + (leg.distance?.value || 0)
    }, 0)

    const routeTime = directionRoute.legs.reduce((sum, leg) => {
      return sum + (leg.duration?.value || 0)
    }, 0)

    if (
      !route ||
      (opts.basedOn === 'DISTANCE' && (meters === 0 || meters >= routeMeters)) ||
      (opts.basedOn === 'TIME' && (time === 0 || time >= routeTime))
    ) {
      route = directionRoute
      time = routeTime
      meters = routeMeters
    }
  })

  return {
    time, // In seconds
    meters,
    route,
  }
}
