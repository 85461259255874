<template>
  <span v-if="!isRetour">Enkele reis</span>
  <span v-else-if="deelRetour">Terugreis<span v-if="!short"> van deze rit</span></span>
  <span v-else>Heenreis<span v-if="!short"> van deze rit</span></span>
</template>

<script>
export default {
  props: {
    isRetour: { type: Boolean, required: true }, // Of de rit een heen- en terugrit is
    deelRetour: { type: Boolean, required: true }, // Of dit deel van de rit een retour, of de heenreis is
    short: Boolean,
  },
}
</script>
