<template>
  <button
    v-if="persona"
    @click="showPersona"
    class="whitespace-nowrap btn smaller uppercase bg-indigo-400 text-white font-bold text-xs"
  >
    {{ persona }} <i class="fas fa-info-circle" v-if="!compact"></i>
  </button>
</template>

<script>
import PERSONAS from '@/constants/PERSONAS'

export default {
  props: {
    persona: String,
    compact: Boolean,
  },
  setup(props) {
    const showPersona = () => {
      if (!props.persona || !PERSONAS[props.persona]) return
      const persona = String(props.persona).toUpperCase()
      window.alert(PERSONAS[persona])
    }
    return { showPersona }
  },
}
</script>
