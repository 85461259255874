import {SETTINGS_BEREKENING as SETTINGS_BEREKENING_V24} from 'berekeningen/LUCHTHAVENVERVOERKEVIN/v24/SETTINGS_BEREKENING'
import {BerekeningsContextType} from 'types/Berekening'
import type {SettingsBerekeningType} from 'types/Settings'

export const SETTINGS_BEREKENING: Readonly<SettingsBerekeningType> = Object.freeze({
  ...SETTINGS_BEREKENING_V24,

  prijsEersteKM: 71,
  gedeeldVervoerOpslag: 0,

  overwrites: {} as Partial<BerekeningsContextType>,
})
