import type {
  BerekeningResult,
  BerekeningResultDetails,
  BerekeningsContextType,
  BerekeningsParamsDTOType,
} from 'types/Berekening'
import type {
  SettingsBerekeningType,
  SettingsParkingKostenType,
  SettingsVasteAdresKostenType,
  SettingsVasteAfstandKostenType,
} from 'types/Settings'

import {isSommigeBuitenland, isVolledigBuitenland} from 'berekeningen/utils/getBuitenland'
import {getLuchthavenParkingKosten} from 'berekeningen/utils/getLuchthavenParkingKosten'
import {getMinimumPrijs} from 'berekeningen/utils/getMinimumPrijs'
import {EMPTY_PERSONEN_KOST, getPersonenKost} from 'berekeningen/utils/getPersonenKosten'
import {getVasteAfstandKosten} from 'berekeningen/utils/getVasteAfstandKosten'
import {log} from 'berekeningen/utils/logger'

import {getBerekeningsContext} from 'berekeningen/utils/getBerekeningsContext'

export const berekeningAlgorithm =
  (
    SETTINGS_BEREKENING: Readonly<SettingsBerekeningType>,
    SETTINGS_PARKING_KOSTEN: Readonly<SettingsParkingKostenType>,
    SETTINGS_VASTE_ADRES_KOSTEN: Readonly<SettingsVasteAdresKostenType>,
    SETTINGS_VASTE_AFSTAND_KOSTEN: Readonly<SettingsVasteAfstandKostenType>,
  ) =>
  (BERKENINGS_CONTEXT_DTO: BerekeningsParamsDTOType): Readonly<BerekeningResult> => {
    const overwrites: Partial<BerekeningsContextType> = Object.assign({}, SETTINGS_BEREKENING.overwrites || {})

    let BERKENINGS_CONTEXT = getBerekeningsContext(BERKENINGS_CONTEXT_DTO, overwrites)

    if (
      SETTINGS_BEREKENING.luxeMoetPriveVervoerZijn &&
      BERKENINGS_CONTEXT.is_luxe_vervoer &&
      !BERKENINGS_CONTEXT.is_prive_vervoer
    ) {
      overwrites.is_prive_vervoer = true
      BERKENINGS_CONTEXT = getBerekeningsContext(BERKENINGS_CONTEXT_DTO, overwrites)
    }

    const {
      is_retour,
      kilometers_heen,
      personen_heen,
      bagage_heen,
      handbagage_heen,
      kost_tol_heen,
      kilometers_retour,
      personen_retour,
      bagage_retour,
      handbagage_retour,
      kost_tol_retour,
      is_ophaal_luchthaven,
      is_bestemming_luchthaven,
      is_wakeup_call,
      is_prive_vervoer,
      is_budget_vervoer,
      is_gedeeld_vervoer,
      is_luxe_vervoer,
      is_last_minute,
      korting,
      korting_promotie,
      korting_afwijking,
      btw_percentage,
      extra_kost,
      prijs_verhoging,
    } = BERKENINGS_CONTEXT

    const kostHeenPersonen = getPersonenKost(personen_heen, SETTINGS_BEREKENING)
    const kostRetourPersonen = !is_retour
      ? {...EMPTY_PERSONEN_KOST}
      : getPersonenKost(personen_retour, SETTINGS_BEREKENING)

    let p_heen = 0
    let p_retour = 0

    const details: BerekeningResultDetails = {
      korting,
      korting_promotie,
      korting_afwijking: 0,
      btw_percentage,
      extra_kost,
      prijs_verhoging,
      personen_heen,
      bagage_heen,
      handbagage_heen,
      personen_retour,
      bagage_retour,
      handbagage_retour,
      kilometers_heen,
      kilometers_retour,
      kost_tol_heen,
      kost_tol_retour,
      kost_personen_heen: 0,
      kost_personen_retour: 0,
      kost_vaste_afstand_heen: 0,
      kost_vaste_afstand_retour: 0,
      kost_parking_heen: 0,
      kost_parking_retour: 0,
      kost_algemene_opslag_heen: 0,
      kost_algemene_opslag_retour: 0,
      kost_gedeeld_vervoer_opslag_heen: 0,
      kost_gedeeld_vervoer_opslag_retour: 0,
      kost_extra_bagage_heen: 0,
      kost_extra_bagage_retour: 0,
      kost_extra_handbagage_heen: 0,
      kost_extra_handbagage_retour: 0,
      kost_volledig_buitenland: 0,
      kost_enkel_luchthaven: 0,
      kost_prive_vervoer: 0,
      kost_luxe_vervoer: 0,
      kost_wakeup_call: 0,
      kost_last_minute: 0,
    }

    // -------------------------------------------------------------
    // Standaard berekening kost/km
    if (kilometers_heen <= SETTINGS_BEREKENING.eersteKM) {
      p_heen += SETTINGS_BEREKENING.prijsEersteKM
    } else {
      p_heen +=
        SETTINGS_BEREKENING.prijsEersteKM +
        (kilometers_heen - SETTINGS_BEREKENING.eersteKM) * SETTINGS_BEREKENING.prijsPerExtraKM
    }

    if (is_retour) {
      if (kilometers_retour <= SETTINGS_BEREKENING.eersteKM) {
        p_retour += SETTINGS_BEREKENING.prijsEersteKM
      } else {
        p_retour +=
          SETTINGS_BEREKENING.prijsEersteKM +
          (kilometers_retour - SETTINGS_BEREKENING.eersteKM) * SETTINGS_BEREKENING.prijsPerExtraKM
      }
    }

    // -------------------------------------------------------------
    // Minimumprijs o.b.v. adressen
    const MINIMUM_PRIJS_ENKELE_RIT_HEEN = getMinimumPrijs(
      BERKENINGS_CONTEXT.adressen_heen,
      BERKENINGS_CONTEXT,
      SETTINGS_BEREKENING,
      SETTINGS_VASTE_ADRES_KOSTEN,
    )
    p_heen = Math.max(MINIMUM_PRIJS_ENKELE_RIT_HEEN, p_heen)

    if (is_retour) {
      const MINIMUM_PRIJS_ENKELE_RIT_RETOUR = getMinimumPrijs(
        BERKENINGS_CONTEXT.adressen_retour,
        BERKENINGS_CONTEXT,
        SETTINGS_BEREKENING,
        SETTINGS_VASTE_ADRES_KOSTEN,
      )
      p_retour = Math.max(MINIMUM_PRIJS_ENKELE_RIT_RETOUR, p_retour)
    }
    log('Minimum', {p_heen, p_retour})

    // -------------------------------------------------------------
    // V17: Prijs stijging per kilometer range:
    details.kost_vaste_afstand_heen = getVasteAfstandKosten(kilometers_heen, SETTINGS_VASTE_AFSTAND_KOSTEN)
    p_heen += details.kost_vaste_afstand_heen
    if (is_retour) {
      details.kost_vaste_afstand_retour = getVasteAfstandKosten(kilometers_retour, SETTINGS_VASTE_AFSTAND_KOSTEN)
      p_retour += details.kost_vaste_afstand_retour
    }
    log('Vaste afstand', {p_heen, p_retour, kilometers_heen, kilometers_retour, SETTINGS_VASTE_AFSTAND_KOSTEN})

    // -------------------------------------------------------------
    // V19: Prijs stijging indien enkel buitenlandse adressen:
    let kostEnkelBuitenland = Number(SETTINGS_BEREKENING.volledigBuitenlandOpslag)
    if (
      kostEnkelBuitenland > 0 &&
      isVolledigBuitenland([...BERKENINGS_CONTEXT.adressen_heen, ...BERKENINGS_CONTEXT.adressen_retour])
    ) {
      details.kost_volledig_buitenland = Number(kostEnkelBuitenland)
      if (is_retour) {
        kostEnkelBuitenland = kostEnkelBuitenland / 2
        p_retour += kostEnkelBuitenland
      }
      p_heen += kostEnkelBuitenland
    }
    log('Volledig buitenland opslag', {p_heen, p_retour})

    // -------------------------------------------------------------
    // V25: Prijs stijging parking kosten luchthaven:
    details.kost_parking_heen += getLuchthavenParkingKosten(BERKENINGS_CONTEXT.adressen_heen, SETTINGS_PARKING_KOSTEN)
    p_heen += details.kost_parking_heen
    if (is_retour) {
      details.kost_parking_retour += getLuchthavenParkingKosten(
        BERKENINGS_CONTEXT.adressen_retour,
        SETTINGS_PARKING_KOSTEN,
      )
      p_retour += details.kost_parking_retour
    }
    log('Parking kosten', {p_heen, p_retour})

    // -------------------------------------------------------------

    p_heen = Number(Math.round(p_heen))
    p_retour = Number(Math.round(p_retour))

    // -------------------------------------------------------------
    // Personen

    if (kostHeenPersonen.busjes > 1) {
      const busjesPrijsHeen = Math.max(0, kostHeenPersonen.busjes - 1) * p_heen
      p_heen += busjesPrijsHeen
    }

    if (is_retour && kostRetourPersonen.busjes > 1) {
      const busjesPrijsRetour = Math.max(0, kostRetourPersonen.busjes - 1) * p_retour
      p_retour += busjesPrijsRetour
    }
    log('Busjes multiplier', {p_heen, p_retour})

    // -------------------------------------------------------------
    // Enkele opslagen

    // Globale opslag
    if (SETTINGS_BEREKENING.opslag > 0) {
      details.kost_algemene_opslag_heen = Math.max(1, kostHeenPersonen.busjes) * SETTINGS_BEREKENING.opslag
      p_heen += details.kost_algemene_opslag_heen
      if (is_retour) {
        details.kost_algemene_opslag_retour = Math.max(1, kostHeenPersonen.busjes) * SETTINGS_BEREKENING.opslag
        p_retour += details.kost_algemene_opslag_retour
      }
    }
    log('Opslag non-budget', {p_heen, p_retour, is_budget_vervoer, opslag: SETTINGS_BEREKENING.opslag})

    // luchthaven kost voor 'enkele' rit
    if (!is_retour && SETTINGS_BEREKENING.ritLuchthavenKost > 0) {
      if (is_ophaal_luchthaven || is_bestemming_luchthaven) {
        details.kost_enkel_luchthaven = SETTINGS_BEREKENING.ritLuchthavenKost
      }
    }
    p_heen += details.kost_enkel_luchthaven
    log('Luchthaven bij enkele rit', {
      p_heen,
      p_retour,
      is_retour,
      is_ophaal_luchthaven,
      is_bestemming_luchthaven,
      opslag: SETTINGS_BEREKENING.ritLuchthavenKost,
    })

    /**
     * V24: Opslag extra bagage (1 handbagage en 1 grote bagage inbegrepen)
     * Indien 1 persoon, en 2 grote bv, dan ook 1 bagage aanrekenen
     */
    if (SETTINGS_BEREKENING.extraBagageOpslag > 0) {
      const extraGroteBagageHeen = Math.max(0, bagage_heen - personen_heen)
      const extraHandBagageHeen = Math.max(0, handbagage_heen - personen_heen)
      details.kost_extra_bagage_heen = extraGroteBagageHeen * SETTINGS_BEREKENING.extraBagageOpslag
      details.kost_extra_handbagage_heen = extraHandBagageHeen * SETTINGS_BEREKENING.extraBagageOpslag
      p_heen += details.kost_extra_bagage_heen + details.kost_extra_handbagage_heen
      if (is_retour) {
        const extraGroteBagageRetour = Math.max(0, bagage_retour - personen_retour)
        const extraHandBagageRetour = Math.max(0, handbagage_retour - personen_retour)
        details.kost_extra_bagage_retour = extraGroteBagageRetour * SETTINGS_BEREKENING.extraBagageOpslag
        details.kost_extra_handbagage_retour = extraHandBagageRetour * SETTINGS_BEREKENING.extraBagageOpslag
        p_retour += details.kost_extra_bagage_retour + details.kost_extra_handbagage_retour
      }
    }
    log('Extra pagage opslag', {p_heen, p_retour, opslag: SETTINGS_BEREKENING.extraBagageOpslag})

    /**
     * Opslag gedeeld vervoer voor deze berekening, zoals V10
     * Maar dan wel niet meegerekend voor chauffeur omzet!
     * Niet wanneer andere minimumprijs is gevonden!
     */
    if (is_gedeeld_vervoer && SETTINGS_BEREKENING.gedeeldVervoerOpslag > 0) {
      details.kost_gedeeld_vervoer_opslag_heen =
        Math.max(1, kostHeenPersonen.busjes) * SETTINGS_BEREKENING.gedeeldVervoerOpslag
      p_heen += details.kost_gedeeld_vervoer_opslag_heen
      if (is_retour) {
        details.kost_gedeeld_vervoer_opslag_retour =
          Math.max(1, kostRetourPersonen.busjes) * SETTINGS_BEREKENING.gedeeldVervoerOpslag
        p_retour += details.kost_gedeeld_vervoer_opslag_retour
      }
    }
    log('Gedeeld vervoer opslag', {
      p_heen,
      p_retour,
      is_gedeeld_vervoer,
      opslag: SETTINGS_BEREKENING.gedeeldVervoerOpslag,
    })

    /**
     * Prijs verhoging WEL in standaard prijs bijrekenen
     */
    if (prijs_verhoging && prijs_verhoging > 0) {
      if (is_retour) {
        p_retour += prijs_verhoging / 2
        p_heen += prijs_verhoging / 2
      } else {
        p_heen += prijs_verhoging
      }
    }
    log('prijs_verhoging', {p_heen, p_retour, prijs_verhoging})

    let prijs_standaard_heen = Math.round(p_heen)
    let prijs_standaard_retour = Math.round(p_retour)
    log('Standaard prijs', {p_heen, p_retour, prijs_standaard_heen, prijs_standaard_retour})

    /**
     * Extra kost NIET in standaard prijs bijrekenen
     */
    if (extra_kost && extra_kost > 0) {
      if (is_retour) {
        p_retour += extra_kost / 2
        p_heen += extra_kost / 2
      } else {
        p_heen += extra_kost
      }
    }
    log('extra_kost', {p_heen, p_retour, extra_kost})

    // Personen kost
    if (kostHeenPersonen.kost > 0) {
      details.kost_personen_heen = kostHeenPersonen.kost
      p_heen += kostHeenPersonen.kost
    }
    if (is_retour && kostRetourPersonen.kost > 0) {
      details.kost_personen_retour = kostRetourPersonen.kost
      p_retour += kostRetourPersonen.kost
    }
    log('Personen kost', {p_heen, p_retour, personen_heen, personen_retour})

    // -------------------------------------------------------------
    // Kortingen/Prijs verhoging:

    if (korting && korting > 0) {
      if (is_retour) {
        const kortingRetour = Math.min(korting / 2, p_retour)
        p_retour -= kortingRetour
        p_heen -= korting - kortingRetour
      } else {
        p_heen -= korting
      }
    }
    log('Korting', {p_heen, p_retour, korting})

    if (korting_promotie && korting_promotie > 0) {
      if (is_retour) {
        const kortingRetour = Math.min(korting_promotie / 2, p_retour)
        p_retour -= kortingRetour
        p_heen -= korting_promotie - kortingRetour
      } else {
        p_heen -= korting_promotie
      }
    }
    log('Korting promotie', {p_heen, p_retour, korting_promotie})

    if (is_gedeeld_vervoer && korting_afwijking && korting_afwijking > 0) {
      details.korting_afwijking = korting_afwijking
      if (is_retour) {
        const kortingRetour = Math.min(korting_afwijking / 2, p_retour)
        p_retour -= kortingRetour
        p_heen -= korting_afwijking - kortingRetour
      } else {
        p_heen -= korting_afwijking
      }
    }
    log('Korting afwijking', {p_heen, p_retour, korting_afwijking, is_gedeeld_vervoer})

    // -------------------------------------------------------------
    // Extras:

    // Tolkosten heen
    if (kost_tol_heen > 0) {
      p_heen += kost_tol_heen * kostHeenPersonen.busjes
    }
    // Tolkosten retour
    if (is_retour && kost_tol_retour > 0) {
      p_retour += kost_tol_retour * kostRetourPersonen.busjes
    }
    log('Tolkosten', {p_heen, p_retour, kost_tol_heen, kost_tol_retour})

    // Prijs Prive vervoer
    if (is_prive_vervoer && SETTINGS_BEREKENING.priveVervoerKostEnkeleReis > 0) {
      const coeffPriveHeen = isSommigeBuitenland(BERKENINGS_CONTEXT.adressen_heen)
        ? SETTINGS_BEREKENING.priveVervoerSommigeBuitenlandMultiplier
        : 1
      details.kost_prive_vervoer =
        SETTINGS_BEREKENING.priveVervoerKostEnkeleReis * kostHeenPersonen.busjes * coeffPriveHeen
      p_heen += details.kost_prive_vervoer
      if (is_retour) {
        const coeffPriveRetour = isSommigeBuitenland(BERKENINGS_CONTEXT.adressen_retour)
          ? SETTINGS_BEREKENING.priveVervoerSommigeBuitenlandMultiplier
          : 1
        details.kost_prive_vervoer =
          SETTINGS_BEREKENING.priveVervoerKostEnkeleReis * kostRetourPersonen.busjes * coeffPriveRetour
        p_retour += details.kost_prive_vervoer
      }
    }
    log('Prive vervoer', {
      p_heen,
      p_retour,
      is_prive_vervoer,
      opslag: SETTINGS_BEREKENING.priveVervoerKostEnkeleReis,
      multiplier_buitenland: SETTINGS_BEREKENING.priveVervoerSommigeBuitenlandMultiplier,
    })

    // Prijs Luxe vervoer
    if (is_luxe_vervoer && SETTINGS_BEREKENING.luxeExtraKostEnkeleReis > 0) {
      p_heen += SETTINGS_BEREKENING.luxeExtraKostEnkeleReis
      details.kost_luxe_vervoer += SETTINGS_BEREKENING.luxeExtraKostEnkeleReis
      if (is_retour) {
        p_retour += SETTINGS_BEREKENING.luxeExtraKostEnkeleReis
        details.kost_luxe_vervoer += SETTINGS_BEREKENING.luxeExtraKostEnkeleReis
      }
    }
    log('Luxe vervoer', {
      p_heen,
      p_retour,
      is_luxe_vervoer,
      opslag: SETTINGS_BEREKENING.luxeExtraKostEnkeleReis,
    })

    // Wake up call
    if (is_wakeup_call && SETTINGS_BEREKENING.wakeUpCallKost > 0) {
      details.kost_wakeup_call = SETTINGS_BEREKENING.wakeUpCallKost
      p_heen += SETTINGS_BEREKENING.wakeUpCallKost
    }
    log('Wakeup call', {p_heen, p_retour, is_wakeup_call, opslag: SETTINGS_BEREKENING.wakeUpCallKost})

    // Last minute
    if (is_last_minute && SETTINGS_BEREKENING.lastMinuteKost > 0) {
      details.kost_last_minute = SETTINGS_BEREKENING.lastMinuteKost
      p_heen += SETTINGS_BEREKENING.lastMinuteKost
    }
    log('Last-Minute', {p_heen, p_retour, is_last_minute, opslag: SETTINGS_BEREKENING.lastMinuteKost})

    p_heen = (p_heen / 1.06) * (btw_percentage / 100 + 1)
    prijs_standaard_heen = (prijs_standaard_heen / 1.06) * (btw_percentage / 100 + 1)
    if (is_retour) {
      p_retour = (p_retour / 1.06) * (btw_percentage / 100 + 1)
      prijs_standaard_retour = (prijs_standaard_retour / 1.06) * (btw_percentage / 100 + 1)
    }

    const result: BerekeningResult = {
      details, // @TODO: Backward compatibility for now ... delete after v2
      prijs_heen: p_heen,
      prijs_retour: !is_retour ? 0 : p_retour,
      prijs_standaard_heen: prijs_standaard_heen,
      prijs_standaard_retour: !is_retour ? 0 : prijs_standaard_retour,
      prijs: 0, // Ingevuld hierna, toegevoegd voor type checking
      standaard: 0, // Ingevuld hierna, toegevoegd voor type checking
    }

    result.prijs = result.prijs_heen + result.prijs_retour
    result.standaard = result.prijs_standaard_heen + result.prijs_standaard_retour

    Object.keys(result.details).forEach(_key => {
      const key = _key as keyof typeof result.details
      result.details[key] = Number(result.details[key])
    })

    Object.keys(result).forEach((_key: string) => {
      const key = _key as keyof typeof result
      if (key === 'details') return
      result[key] = Math.max(0, Number(result[key].toFixed(2)))
    })

    return Object.freeze(result)
  }
