<template>
  <div v-if="chauffeur_id > 0">
    <TableRittenWinstmargeOnderaannemingTemplate :title="`Winstmarge onderaanneming - ${store.getters.chauffeur(chauffeur_id)?.label}`" :userId="chauffeur_id" />
  </div>
</template>

<script setup>
import { defineAsyncComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const TableRittenWinstmargeOnderaannemingTemplate = defineAsyncComponent(() => import('@/components/Table/Ritten/WinstmargeOnderaannemingTemplate.vue'))

const route = useRoute()
const store = useStore()

const chauffeur_id = computed(() => route.query.chauffeur_id)
</script>

