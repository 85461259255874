import type {SettingsBerekeningType} from 'types/Settings'

import {SETTINGS_BEREKENING as SETTINGS_BEREKENING_V1001} from 'berekeningen/TAXILUCHTHAVEN/v1001/SETTINGS_BEREKENING'

export const SETTINGS_BEREKENING: Readonly<SettingsBerekeningType> = Object.freeze({
  ...SETTINGS_BEREKENING_V1001,

  eersteKM: 30,
  personenKost: [
    0,
    0,
    5,
    10,
    15,
    20,
    25,
    30,
    'aanvraag', // Laatste enkel op afspraak!
  ] as SettingsBerekeningType['personenKost'],
})
