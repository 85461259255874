<template>
  <div v-if="data?.statistieken">
    <UITableResponsive :items="data.statistieken || []" class="text-center w-auto" :headers="headers">
      <template v-slot:item-Datum="{ item }">
        <div class="flex flex-row gap-1">
          <span v-if="item.day">{{ item.day }}</span>
          <span v-if="item.month">{{ monthToString(item.month) }}</span>
          <span v-if="item.year">{{ item.year }}</span>
        </div>
      </template>
      <template v-slot:item-Totaal_prijs="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_prijs) }}
        </div>
      </template>
      <template v-slot:item-Totaal_standaard="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_standaard_prijs) }}
        </div>
      </template>
      <template v-slot:item-Totaal_korting="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_korting) }}
        </div>
      </template>
      <template v-slot:item-Totaal_korting_promotie="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_korting_promotie) }}
        </div>
      </template>
      <template v-slot:item-Reservaties="{ item }">
        <div>
          <i class="fas fa-handshake text-gray-400"></i>
          {{ item.aantal_reservaties }}
        </div>
      </template>
      <template v-slot:item-Enkele_ritten="{ item }">
        <div>
          <i class="fas fa-car text-gray-400"></i>
          {{ item.aantal_enkele_ritten }}
        </div>
      </template>
      <template v-slot:item-Prive_ritten="{ item }">
        <div>
          {{ item.aantal_prive_ritten }} (&euro;&nbsp;{{ formatPrice(item.totaal_kost_prive_vervoer) }})
        </div>
      </template>
      <template v-slot:item-Last_minutes="{ item }">
        <div>
          {{ item.aantal_last_minute_vervoer }} (&euro;&nbsp;{{ formatPrice(item.totaal_kost_last_minute) }})
        </div>
      </template>
      <template v-slot:item-Wakeup_calls="{ item }">
        <div>
          {{ item.aantal_wakeup_calls }} (&euro;&nbsp;{{ formatPrice(item.totaal_kost_wakeup_call) }})
        </div>
      </template>
      <template v-slot:item-Getekend="{ item }">
        <div>
          {{ item.aantal_ritten_getekend }}
        </div>
      </template>
      <template v-slot:item-Kilometers="{ item }">
        {{ formatDecimals(item.totaal_kilometers, 2) }} km
      </template>
      <template v-slot:item-Seconden="{ item }">
        <div>
          {{ item.totaal_seconden }} s
        </div>
      </template>
      <template v-slot:item-Aantal_personen="{ item }">
        <div>
          <i class="fas fa-users text-gray-400"></i> {{ item.aantal_personen }}
        </div>
      </template>
      <template v-slot:item-Personen_kost="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_kost_personen) }}
        </div>
      </template>
      <template v-slot:item-Prijs_verhoging="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_prijs_verhoging) }}
        </div>
      </template>
      <template v-slot:item-Extra_kost="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_extra_kost) }}
        </div>
      </template>
      <template v-slot:item-Tolkosten="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_kost_tol) }}
        </div>
      </template>
      <template v-slot:item-Kosten_ophaling_luchthaven="{ item }">
        <div>
          &euro;&nbsp;{{ formatPrice(item.totaal_kost_enkel_luchthaven) }}
        </div>
      </template>
    </UITableResponsive>

    <div v-if="data.statistieken.length > 1" :key="selectedCategory" class="mt-8">
      <UISelect label="Grafiek" :options="categories" v-model="selectedCategory" />
      <ChartsBar :label="selectedCategory" :categories="chartCategories" :values="chartValues" />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, ref } from 'vue'
import clone from 'just-clone'

import useGetApi from '@/hooks/useGetApi'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UISelect from '@/components/UI/Select/Select.vue'

import { monthToString } from '@/functions/formatDate'
import { formatPrice, formatDecimals } from '@/functions/formatNumber'
import { formatHeader } from '@/functions/formatText'

const ChartsBar = defineAsyncComponent(() => import('@/components/Charts/Bar.vue'))

const props = defineProps({
  is_bevestigd: {
    type: Boolean,
    default: true,
  },
  timefield: {
    type: String,
    default: null,
  },
  filters: {
    type: Object,
    required: true,
  },
})

const headers = [
  'Datum',

  'Totaal_prijs', // totaal_prijs
  'Totaal_standaard', // totaal_standaard_prijs
  'Totaal_korting', // totaal_korting

  // 'Reservaties', // aantal_reservaties
  'Enkele_ritten', // aantal_enkele_ritten

  'Prive_ritten', // aantal_prive_ritten totaal_kost_prive_vervoer
  'Last_minutes', // aantal_last_minute_vervoer totaal_kost_last_minute
  'Wakeup_calls', // aantal_wakeup_calls totaal_kost_wakeup_call
  'Getekend', // aantal_ritten_getekend

  'Kilometers', // totaal_kilometers
  'Seconden', // totaal_seconden
  'Aantal_personen', // aantal_personen
  'Personen_kost', // totaal_kost_personen

  'Prijs_verhoging', // totaal_prijs_verhoging
  'Extra_kost', // totaal_extra_kost
  'Tolkosten', // totaal_kost_tol

  'Kosten_ophaling_luchthaven', // totaal_kost_enkel_luchthaven
]

const serieValues = {
  Datum: (item) => [item.day, item.month ? monthToString(item.month) : null, item.year].filter(el => !!el).join(' '),
  Totaal_prijs: (item) => item.totaal_prijs,
  Totaal_standaard: (item) => item.totaal_standaard_prijs,
  Totaal_korting: (item) => item.totaal_korting,
  Totaal_korting_promotie: (item) => item.totaal_korting_promotie,
  // Reservaties: (item) => item.aantal_reservaties,
  Enkele_ritten: (item) => item.aantal_enkele_ritten,
  Prive_ritten: (item) => item.aantal_prive_ritten,
  Last_minutes: (item) => item.aantal_last_minute_vervoer,
  Wakeup_calls: (item) => item.aantal_wakeup_calls,
  Getekend: (item) => item.aantal_ritten_getekend,
  Kilometers: (item) => item.totaal_kilometers,
  Seconden: (item) => item.totaal_seconden,
  Aantal_personen: (item) => item.aantal_personen,
  Personen_kost: (item) => item.totaal_kost_personen,
  Prijs_verhoging: (item) => item.totaal_prijs_verhoging,
  Extra_kost: (item) => item.totaal_extra_kost,
  Tolkosten: (item) => item.totaal_kost_tol,
  Kosten_ophaling_luchthaven: (item) => item.totaal_kost_enkel_luchthaven,
}

const apiData = computed(() => {
  const result = clone(props.filters)
  result.is_bevestigd = props.is_bevestigd
  result.timefield = props.timefield
  return result
})
const { data } = useGetApi('/api/dashboard/stats/ritten', apiData, { watch: true })

const selectedCategory = ref('Totaal_prijs')

const categories = computed(() => {
  if (!data.value?.statistieken) return []
  return Object.keys(serieValues).filter(el => el !== 'Datum').map(el => ({ label: formatHeader(el), value: el }))
})
const chartCategories = computed(() => {
  if (!data.value?.statistieken) return []
  return data.value.statistieken.map(item => serieValues.Datum(item))
})
const chartValues = computed(() => {
  if (!data.value?.statistieken) return []
  return data.value.statistieken.map(item => serieValues[selectedCategory.value].call(serieValues, item) || 0)
})
</script>
