import type {BerekeningsContextType} from 'types/Berekening'
import type {SettingsBerekeningType} from 'types/Settings'

export const SETTINGS_BEREKENING: Readonly<SettingsBerekeningType> = Object.freeze({
  minimumPrijsEnkeleRit: 48,

  eersteKM: 30,
  prijsEersteKM: 66,
  prijsPerExtraKM: 1,

  ritLuchthavenKost: 5,
  lastMinuteKost: 20,
  wakeUpCallKost: 5,
  personenKost: [
    0,
    0,
    5,
    10,
    15,
    20,
    30,
    40,
    'aanvraag', // Laatste enkel op afspraak!
  ] as SettingsBerekeningType['personenKost'],

  priveVervoerKostEnkeleReis: 20,
  priveVervoerSommigeBuitenlandMultiplier: 1,

  luxeExtraKostEnkeleReis: 0,
  luxeMoetPriveVervoerZijn: false,

  gedeeldVervoerOpslag: 5,
  opslag: 0,
  extraBagageOpslag: 0,
  volledigBuitenlandOpslag: 50,

  overwrites: {
    // Tot v25 was dit een fout in de berekening.
    is_gedeeld_vervoer: true,
  } as Partial<BerekeningsContextType>,
})
