import type {SettingsBerekeningType} from 'types/Settings'

export const EMPTY_PERSONEN_KOST = {kost: 0, busjes: 0}

export const getPersonenKost = (personenParam = 1, SETTINGS_BEREKENING: SettingsBerekeningType) => {
  const personen = Math.max(personenParam || 1, 1)

  let kost = 0
  let busjesNodig = 0

  const kostenlijst: number[] = (SETTINGS_BEREKENING.personenKost || []).reduce((acc, kost) => {
    if (typeof kost === 'number') acc.push(kost)
    return acc
  }, [] as number[])

  const acht_man = kostenlijst[kostenlijst.length - 1]

  if (personen > 8) {
    // Aantal volle busjes
    busjesNodig = Math.floor(personen / 8)
    kost += busjesNodig * acht_man

    // Rest aantal personen in niet vol busje
    const restPersonen = Math.min(7, Math.max(0, personen - busjesNodig * 8))
    if (restPersonen > 0) {
      busjesNodig += 1
    }
    kost += kostenlijst[restPersonen - 1] || 0
  } else {
    kost += kostenlijst[personen - 1] || 0
  }

  return {
    kost,
    busjes: Math.max(1, busjesNodig),
  }
}
