import {parseAdressen} from 'berekeningen/utils/parseAdressen'
import type {SettingsParkingKostenType} from 'types/Settings'

export const getLuchthavenParkingKosten = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adressenParam: any[] = [],
  PARKING_KOSTEN_SETTINGS: SettingsParkingKostenType | null = null,
): number => {
  if (!PARKING_KOSTEN_SETTINGS) return 0

  const adressen = parseAdressen(adressenParam)

  if (!adressen.length) {
    console.warn('Berekening zonder opgegeven adressen in params!', {adressenParam})
    return 0
  }

  const result = adressen
    .map(el => String(el).toUpperCase())
    .reduce((n, adres) => {
      if (
        !adres.includes('LUCHTHAVEN') &&
        !adres.includes('AIRPORT') &&
        !adres.includes('AÉROPORT') &&
        !adres.includes('AEROPORT')
      ) {
        return n
      }
      if (adres.includes('CHARLEROI')) {
        return n + Number(PARKING_KOSTEN_SETTINGS['CHARLEROI'] || 0)
      }
      return n
    }, 0)

  return Math.round(result)
}
