<template>
  <div>
    <h1>Nieuwe rit inboeken</h1>
    <router-link replace :to="{ path: route.path.replace('ritten', 'offerte'), query: { ...route.query, storageKey } }">
      <i class="fas fa-sync text-xs"></i> Naar offerte omvormen
    </router-link>
    <FormRit v-model="formInStorage" @submit="onSubmit" />
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import FormRit from '@/components/Form/Rit.vue'
import useApi from '@/hooks/useApi'
import useRedirectSafe from '@/hooks/useRedirectSafe'
import { dateString } from '@/functions/formatDate'
import { useStorage } from '@vueuse/core'

const api = useApi()
const route = useRoute()
const router = useRouter()

const storageKey = route.query.storageKey || `RitData-${Date.now()}`
const formInStorage = useStorage(storageKey, {})
if (!route.query.storageKey) router.replace({ query: { ...route.query, storageKey } })

const onSubmit = data => {
  api('RIT_CREATE', data).then(() => {
    const date = dateString(data.heenreis.datum) || undefined
    if (formInStorage) formInStorage.value = null
    // if (route.query.from) {
    //   useRedirectSafe(decodeURIComponent(route.query.from))
    //   return
    // }
    // console.log({ date, data })
    useRedirectSafe({ name: 'Kalender', query: { date } })
  })
}
</script>
